import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../../supabaseClient'; // Adjust the path as necessary
import Pagination from '../../Components/Pagination'; // Assume you have a pagination component

const WithdrawRequests = () => {
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(10); // Change to set items per page
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.overflow = 'overlay';
  
    // Clean up when the component unmounts
    return () => {
      document.body.style.margin = '';
      document.body.style.padding = '';
      document.body.style.overflow = '';
    };
  }, []);
  
  // Fetch withdrawal requests and add username
  useEffect(() => {
    const fetchWithdrawRequests = async () => {
      setLoading(true);
      try {
        let query = supabase.from('withdraw_requests').select(`*, telegramUsers(username)`);
        if (filterStatus !== 'all') {
          query = query.eq('status', filterStatus);
        }
        const { data, error } = await query;
        if (error) {
          throw error;
        }
        setWithdrawRequests(data);
      } catch (error) {
        console.error("Error fetching withdrawal requests: ", error);
      }
      setLoading(false);
    };

    fetchWithdrawRequests();
  }, [filterStatus]);

  // Pagination logic
  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = withdrawRequests.slice(indexOfFirstRequest, indexOfLastRequest);

  const handleStatusChange = async (id, status) => {
    try {
      const { error } = await supabase
        .from('withdraw_requests')
        .update({ status })
        .eq('id', id);
      if (error) throw error;
      setWithdrawRequests(prevRequests =>
        prevRequests.map(req => (req.id === id ? { ...req, status } : req))
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
<div className="p-6 bg-gray-900 text-white min-h-screen">
  <h1 className="text-2xl font-bold mb-6 text-center">Withdraw Requests</h1>

  <div className="mb-6 flex items-center justify-between">
    <div className="flex items-center">
      <label htmlFor="filter" className="mr-3 text-lg">Filter by Status:</label>
      <select
        id="filter"
        value={filterStatus}
        onChange={e => setFilterStatus(e.target.value)}
        className="p-2 bg-black text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-gray-400"
      >
        <option value="all">All</option>
        <option value="pending">Pending</option>
        <option value="approved">Approved</option>
        <option value="declined">Declined</option>
      </select>
    </div>
  </div>

  {loading ? (
    <div className="text-center text-xl">Loading...</div>
  ) : (
    <>
      {/* Add scrollable wrapper for the table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left bg-gray-800 rounded-lg shadow-lg">
          <thead className="bg-gray-700 text-gray-200">
            <tr>
              <th className="px-4 py-3">User</th>
              <th className="px-4 py-3">Wallet Address</th>
              <th className="px-4 py-3">Request Date</th>
              <th className="px-4 py-3">Amount</th>
              <th className="px-4 py-3">Status</th>
              <th className="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-300">
            {currentRequests.map(request => (
              <tr key={request.id} className="border-b border-gray-700">
                <td className="px-4 py-4">{request.telegramUsers?.username || 'N/A'}</td>
                <td className="px-4 py-4">{request.walletAddress || 'N/A'}</td>
                <td className="px-4 py-4">{new Date(request.requestedAt).toLocaleString()}</td>
                <td className="px-4 py-4">${request.balance}</td>
                <td className="px-4 py-4">{request.status}</td>
                <td className="px-4 py-4">
                  {request.status === 'pending' && (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleStatusChange(request.id, 'approved')}
                        className="bg-green-600 hover:bg-green-700 text-white px-3 py-1 rounded shadow"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => handleStatusChange(request.id, 'declined')}
                        className="bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded shadow"
                      >
                        Decline
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <Pagination
          requestsPerPage={requestsPerPage}
          totalRequests={withdrawRequests.length}
          currentPage={currentPage}
          paginate={setCurrentPage}
        />
      </div>
    </>
  )}
</div>

  );
};

export default WithdrawRequests;
