import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Adjust the path as needed
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { PiArrowRight } from 'react-icons/pi';
import Spinner from './Spinner';

const linksTo = [
  {
      link: '/dashboardAdx/stats',
      title: 'Dashboard',
  },
  {
      link: '/dashboardAdx/managetasks',
      title: 'Project TG Tasks',
  },
  {
      link: '/dashboardAdx/externaltasks',
      title: 'Other Tasks',
  },
  {
      link: '/dashboardAdx/promo',
      title: 'Adverts/Promo Tasks',
  },
  {
      link: '/dashboardAdx/ranks',
      title: 'Users Ranks',
  },
  {
    link: '/dashboardAdx/youtube',
    title: 'Youtube Tasks',
  },
  {
      link: '/dashboardAdx/airdroplist',
      title: 'Airdrop List',
  },
  {
      link: '/dashboardAdx/search',
      title: 'Users list',
  },
  {
      link: '/dashboardAdx/settings',
      title: 'Settings',
  },
];

const StatisticsPanel = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalTapBalance, setTotalTapBalance] = useState(0);
  const [activeUsersLast24Hours, setActiveUsersLast24Hours] = useState(0);
  const [activeUsersLast1Minute, setActiveUsersLast1Minute] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    const last24Hours = moment().subtract(24, 'hours').toISOString();
    const last1Minute = moment().subtract(1, 'minute').toISOString();

    try {
      let { data: users, error } = await supabase
        .from('telegramUsers')
        .select('*');
      
      if (error) throw error;

      const totalUsersCount = users.length;
      setTotalUsers(totalUsersCount);

      const totalBalanceSum = users.reduce((acc, user) => acc + (user.balance || 0), 0);
      const totalTapBalanceSum = users.reduce((acc, user) => acc + (user.mining_total || 0), 0);

      setTotalBalance(totalBalanceSum);
      setTotalTapBalance(totalTapBalanceSum);

      const activeUsers24Hours = users.filter(user => user.last_active && moment(user.last_active).isAfter(last24Hours)).length;
      const activeUsers1Minute = users.filter(user => user.last_active && moment(user.last_active).isAfter(last1Minute)).length;

      setActiveUsersLast24Hours(activeUsers24Hours);
      setActiveUsersLast1Minute(activeUsers1Minute);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }

    const formattedNumber = number.toFixed(8);
    const parts = formattedNumber.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  };

  const statista = [
    {
        title: 'Total Users',
        count: totalUsers,
    },
    {
        title: 'Total Balance',
        count: formatNumber(totalBalance),
    },
    {
        title: 'Total Mining',
        count: formatNumber(totalTapBalance),
    },
    {
        title: 'Last 24hours',
        count: activeUsersLast24Hours,
    },
    {
      title: 'Online Users',
      count: activeUsersLast1Minute,
    },
  ];

  return (
    <>
      {loading ? (
        <Spinner/>
      ) : (
        <div className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px]">
          <div className="w-full flex justify-start items-start flex-wrap gap-4">
            {statista.map((stats, index) => (
              <div key={index} className={`bg-cards p-4 rounded-[10px] w-[47%] sm:w-[32%] h-[120px] flex flex-col justify-center space-y-3 ${statista.length === 5 ? 'last:w-full sm:last:w-[64%]' : '' }`}>
                <h2 className="text-[16px] sm:text-[18px] font-semibold text-[#bdbdbd]">
                  {stats.title}
                </h2>
                <span className='text-[20px] sm:text-[24px] text-[#fff] font-bold'>
                  {stats.count}
                </span>
              </div>
            ))}
          </div>
          <h2 className='font-semibold text-[17px] pt-3'>
            Admin Control Items
          </h2>
          <div className='flex flex-col space-y-4 w-full'>
            {linksTo.map((menu, index) => (
              <NavLink to={menu.link} key={index} className={`bg-cards px-4 py-4 flex rounded-[6px] justify-between items-center space-x-1 font-medium`}>
                <span className=''>
                  {menu.title}
                </span>
                <span className=''>
                  <PiArrowRight size={16} className=''/>
                </span>
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StatisticsPanel;
