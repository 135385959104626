import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

const TopTen = () => {
  const [mineLeaderBoard, setMineLeaderBoard] = useState([]);

  // Fetch top 100 users from 'telegramUsers' to update leaderboard
  const fetchUsers = async () => {
    const { data, error } = await supabase
      .from('telegramUsers')
      .select('userId, username, mining_total, full_name, photo_url')
      .order('mining_total', { ascending: false })
      .limit(100);

    if (error) {
      console.error('Error fetching users:', error);
    }
    return data;
  };

  // Function to update the leaderboard (same as from AdminMineRanks)
  const addUsersToLeaderBoard = async () => {
    const users = await fetchUsers();

    const { data: currentMineLeaderBoard, error } = await supabase
      .from('mineLeaderBoard')
      .select('*')
      .order('mining_total', { ascending: false });

    if (error) {
      console.error('Error fetching leaderboard:', error);
      return;
    }

    let updated = false;

    for (const user of users) {
      const existingUser = currentMineLeaderBoard.find((lbUser) => lbUser.id === user.userId);

      if (existingUser) {
        if (existingUser.mining_total !== user.mining_total) {
          const { error: updateError } = await supabase
            .from('mineLeaderBoard')
            .upsert({
              id: user.userId,
              username: user.username,
              mining_total: user.mining_total,
              full_name: user.full_name || user.username,
            });
          if (updateError) console.error('Error updating leaderboard:', updateError);
          updated = true;
        }
      } else {
        if (currentMineLeaderBoard.length >= 100) {
          const lowestUser = currentMineLeaderBoard[currentMineLeaderBoard.length - 1];
          const { error: deleteError } = await supabase
            .from('mineLeaderBoard')
            .delete()
            .eq('id', lowestUser.id);
          if (deleteError) console.error('Error deleting lowest user:', deleteError);
        }

        const { error: insertError } = await supabase
          .from('mineLeaderBoard')
          .insert({
            id: user.userId,
            username: user.username,
            mining_total: user.mining_total,
            full_name: user.full_name || user.username,
          });
        if (insertError) console.error('Error inserting new user:', insertError);
        updated = true;
      }
    }

    if (updated) {
      fetchMineLeaderBoard();
    }
  };

  // Fetch the leaderboard from Supabase in real-time
  const fetchMineLeaderBoard = async () => {
    const { data, error } = await supabase
      .from('mineLeaderBoard')
      .select('*')
      .order('mining_total', { ascending: false });

    if (error) {
      console.error('Error fetching leaderboard:', error);
      return;
    }
    setMineLeaderBoard(data);
  };

  // Call the leaderboard update function when component is mounted
  useEffect(() => {
    const updateLeaderboardOnVisit = async () => {
      await addUsersToLeaderBoard();
    };

    updateLeaderboardOnVisit();
    fetchMineLeaderBoard();
  }, []); // Empty dependency array ensures it runs once on component mount

  const getInitials = (username) => {
    const nameParts = username.split(' ');
    return nameParts[0].charAt(0).toUpperCase() + nameParts[0].charAt(1).toUpperCase();
  };

  const getRandomColor = () => {
    const colors = ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Custom images for top 5 users
  const rankImages = ['/1st.webp', '/2nd.webp', '/3rd.webp', '/4th.webp', '/5th.webp'];

  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }

    const formattedNumber = number.toFixed(8);
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  return (
    <>
      <div className="w-full flex flex-col space-y-3 pt-3">
        {mineLeaderBoard
          .slice(0, 10)
          .sort((a, b) => b.mining_total - a.mining_total) // Sort users by balance in descending order
          .map((user, index) => (
            <div key={user.userId} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-3">
              <div className="w-fit">
                <div className={`flex items-center justify-center h-[42px] w-[42px] rounded-full p-1 ${getRandomColor()}`}>
                  {user.photo_url ? (
                    <img src={user.photo_url} alt={user.username} className="w-full h-full object-cover rounded-full" />
                  ) : (
                    <span className="font-semibold text-[14px]">{getInitials(user.username)}</span>
                  )}
                </div>
              </div>
              <div className="flex h-full flex-1 flex-col justify-center relative">
                <div className="flex w-full flex-col justify-between h-full space-y-[2px]">
                  <h1 className="text-[14px] text-nowrap line-clamp-1 font-medium">{user.username}</h1>
                  <span className="flex items-center gap-1 flex-1 text-[12px]">
                    <img src="/stars2.svg" alt="star" className="w-[10px]" />
                    <span className="text-[12px] text-nowrap font-medium">{formatNumber(user.mining_total)}</span>
                  </span>
                </div>
              </div>
              <div className="w-fit flex items-center justify-end flex-wrap text-[14px] relative px-4">
                {index < 5 ? (
                  <img src={rankImages[index]} alt={`Rank ${index + 1}`} className="w-[24px] h-auto" />
                ) : (
                  <button className="font-semibold ease-in duration-200">#{index + 1}</button>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default TopTen;