import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../context/userContext';
import { PiLockKeyFill } from 'react-icons/pi';
import { FaCalendarAlt } from 'react-icons/fa';
import { IoCheckmarkCircleSharp, IoClose, IoWarningOutline } from 'react-icons/io5';
import Animate from '../Components/Animate';
import { useNavigate } from 'react-router-dom';

const DailyCheckIn = () => {
  const {
    id,
    checkinRewards,
    setCheckinRewards,
    setLastCheckIn,
    checkInDays,
    setCheckInDays,
    balance,
    setBalance,
  } = useUser();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const [streak, setStreak] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const bonusPoints = [0.005, 0.02, 0.04, 0.06, 0.08, 0.10, 0.15, 0.20, 0.25, 0.30, 0.40, 0.50, 0.60, 0.80, 1];

  useEffect(() => {
    // Back Button Setup
    const handleBackButtonClick = () => navigate('/');
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);

    return () => {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
    };
  }, [navigate]);

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => setError(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  const handleDailyCheckIn = async () => {
    if (!id) return;
    setClaiming(true);

    try {
      const { data: userData, error } = await supabase
        .from('telegramUsers')
        .select('*')
        .eq('userId', id)
        .single();

      if (error) throw error;

      const now = new Date();
      const lastCheckInDate = userData.lastCheckIn ? new Date(userData.lastCheckIn) : null;
      const currentDayIndex = checkInDays.length;

      if (lastCheckInDate) {
        const hoursSinceLastCheckIn = (now - lastCheckInDate) / (1000 * 60 * 60);

        if (hoursSinceLastCheckIn < 24) {
          throw new Error('Next check-in is available tomorrow!');
        } else if (hoursSinceLastCheckIn > 48) {
          resetStreak();
          return;
        }
      }

      const currentBonus = bonusPoints[currentDayIndex % 15];
      const updatedDays = (currentDayIndex + 1) % 15 === 0 ? [] : [...checkInDays, currentDayIndex + 1];
      const updatedData = {
        lastCheckIn: now.toISOString(),
        balance: balance + currentBonus,
        checkinRewards: checkinRewards + currentBonus,
        checkInDays: updatedDays,
      };

      localStorage.setItem('dailyCheckIn', JSON.stringify(updatedData));
      setCheckInDays(updatedDays);
      setCheckinRewards(updatedData.checkinRewards);
      setBalance(updatedData.balance);
      setLastCheckIn(now);

      const { error: updateError } = await supabase
        .from('telegramUsers')
        .update(updatedData)
        .eq('userId', id);

      if (updateError) throw updateError;

      setShowSuccessModal(true);
      setCongrats(true);
      setTimeout(() => setCongrats(false), 4000);
    } catch (err) {
      console.error('Error during daily check-in:', err);
      setError(err.message);
    } finally {
      setClaiming(false);
    }
  };

  const resetStreak = async () => {
    try {
      const now = new Date();
      const currentBonus = bonusPoints[0];
      const resetData = {
        lastCheckIn: now.toISOString(),
        balance: balance + currentBonus,
        checkInDays: [1],
        checkinRewards: checkinRewards + currentBonus,
      };

      localStorage.setItem('dailyCheckIn', JSON.stringify(resetData));
      setCheckInDays([1]);
      setCheckinRewards(resetData.checkinRewards);
      setBalance(resetData.balance);
      setLastCheckIn(now);

      const { error: resetError } = await supabase
        .from('telegramUsers')
        .update(resetData)
        .eq('userId', id);

      if (resetError) throw resetError;

      setShowSuccessModal(true);
      setCongrats(true);
      setTimeout(() => setCongrats(false), 4000);
    } catch (err) {
      console.error('Error resetting streak:', err);
      setError(err.message);
    }
  };

  const renderCheckInBoxes = () => {
    return bonusPoints.map((points, index) => {
      const isCurrentDay = index === checkInDays.length;
      const isCheckedIn = checkInDays.includes(index + 1);
      const isLocked = index > checkInDays.length;

      return (
        <button
          key={index}
          disabled={isCheckedIn || isLocked}
          className={`w-[23%] space-y-1 flex flex-col items-center justify-center relative h-[80px] rounded-[8px] cursor-pointer select-none 
            ${isCurrentDay && !isCheckedIn ? 'bg-blue-700 border-blue-500' : ''}
            ${isCheckedIn ? 'bg-green-600 border-green-100 cursor-not-allowed' : ''}
            ${isLocked ? 'bg-[#343434] border-red-500 cursor-not-allowed' : ''}
          `}
          onClick={() => isCurrentDay && !isCheckedIn && handleDailyCheckIn()}
        >
          <h2 className='text-[10px]'>
            {isLocked ? <PiLockKeyFill size={10} className='text-[#C7C7C7]' /> : `Day ${index + 1}`}
          </h2>
          <img src="/coin.webp" alt="coin" className="w-[16px]" />
          <span className="text-[13px] font-bold">{points.toFixed(3)}</span>
          <span className={`${isCurrentDay ? 'absolute' : 'hidden'} w-[6px] top-1 right-2 h-[6px] bg-white rounded-full ${!isCheckedIn ? 'animate-pulse' : ''}`}></span>
          <span className={`${claiming && isCurrentDay ? 'flex' : 'hidden'} absolute left-0 right-0 top-0 bottom-0 !mt-0 items-center justify-center text-[10px] rounded-[8px] bg-[#414040]`}>
            <em className='animate-pulse not-italic'>Claiming...</em>
          </span>
        </button>
      );
    });
  };

  return (
    <>
      <Animate>
        <div className="w-full flex justify-center flex-col">
          <div id="refer" className="w-full flex flex-col scroller h-[100vh] overflow-y-auto pb-[180px] pt-3 px-4">
            <div className="w-full flex-col pb-4 flex items-center justify-center text-center pt-4">
              <span className="bg-cards h-[70px] w-[70px] rounded-full flex items-center justify-center">
                <FaCalendarAlt size={40} className="text-accent" />
              </span>
              <h1 className="text-center font-bold text-[24px] pt-2">Daily check-in rewards</h1>
              <p className="text-[14px] leading-[24px]">
                Accrue $GAMAUSDT tokens for logging into the game daily and maintain consistency for reward streaks!
              </p>
            </div>
            <div className="w-full flex justify-center gap-2 flex-wrap">{renderCheckInBoxes()}</div>
          </div>
        </div>
        {congrats && (
          <div className="w-full absolute top-[50px] flex justify-center z-50 pointer-events-none select-none">
            <img src="/congrats.gif" alt="congrats" className="w-[80%]" />
          </div>
        )}
        {showSuccessModal && (
          <div className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-[#00000042] flex justify-center items-center">
            <div className="w-full bg-modal rounded-[16px] p-8 text-center">
              <IoCheckmarkCircleSharp size={32} className="text-accent mx-auto" />
              <h3 className="font-medium text-[24px] text-[#ffffff] pb-2">
                +{bonusPoints[checkInDays.length - 1].toFixed(3)} GAMAUSDT
              </h3>
              <p className="pb-6 text-primary text-[15px]">Come back tomorrow to claim another bonus!</p>
              <button
                onClick={() => setShowSuccessModal(false)}
                className="bg-btn4 w-full py-[16px] rounded-[12px] font-medium text-[18px]"
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {error && (
          <div className="fixed z-50 bottom-24 left-0 right-0 px-4">
            <div className="w-full text-red-500 flex items-center space-x-2 bg-[#1a202fef] h-[50px] rounded-[8px] px-4">
              <IoWarningOutline size={16} />
              <span className="text-[15px]">{error}</span>
            </div>
          </div>
        )}
      </Animate>
    </>
  );
};

export default DailyCheckIn;
