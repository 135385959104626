import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useUser } from "../context/userContext";
import { IoCheckmarkCircleSharp, IoCheckmarkCircle, IoClose } from 'react-icons/io5';
import { CiNoWaitingSign } from 'react-icons/ci';
import { supabase } from '../supabaseClient';
import axios from 'axios';

const ManualTasks = () => {
    const [showVerifyButtons, setShowVerifyButtons] = useState({});
    const [countdowns, setCountdowns] = useState({});
    const [buttonText, setButtonText] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [claiming, setClaiming] = useState({});
    const [submittedManual, setSubmittedManual] = useState({});
    const { id: userId, manualTasks, setTaskPoints, userManualTasks, setUserManualTasks, setBalance } = useUser();
    const [claimedBonus, setClaimedBonus] = useState(0);
    const [congrats, setCongrats] = useState(false);
    const [openTask, setOpenTask] = useState(null);
    const [active, setActive] = useState(false);
    const [userCountry, setUserCountry] = useState('');

    useEffect(() => {
        const handleBackButtonClick = () => {
            setOpenTask(false);
        };

        if (openTask) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
        } else {
            window.Telegram.WebApp.BackButton.hide();
            window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
        }

        return () => {
            window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
        };
    }, [openTask]);

    // Fetch user's country using the IP geolocation API
    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=cbfde6a91654442a9ad128a07719153b`);
                setUserCountry(response.data.country_code2);
            } catch (error) {
                console.error('Error fetching user country: ', error);
            }
        };

        fetchCountry();
    }, []);

    const performTask = (taskId) => {
        const task = manualTasks.find(task => task.id === taskId);
        if (task) {
            // Open the task link
            window.open(task.link, '_blank');
    
            // Mark the task as verified after 1 second
            setTimeout(() => {
                setShowVerifyButtons(prevState => ({ ...prevState, [taskId]: true }));
                setSubmittedManual(prevState => ({ ...prevState, [taskId]: true }));
    
                // Optionally, save task progress to the database here
                const saveTaskToUser = async () => {
                    try {
                        const { error } = await supabase
                            .from('telegramUsers')
                            .update({ manualTasks: [...userManualTasks, { taskId: taskId, completed: false }] })
                            .eq('userId', userId);
    
                        if (error) throw error;
    
                        setUserManualTasks(prevTasks => [...prevTasks, { taskId: taskId, completed: false }]);
                    } catch (error) {
                        console.error("Error saving task progress: ", error);
                    }
                };
    
                saveTaskToUser();
            }, 1000);
        }
    };
    
    

    const startCountdown = (taskId) => {
        setCountdowns(prevState => ({ ...prevState, [taskId]: 5}));
        setButtonText(prevState => ({ ...prevState, [taskId]: 'Verifying' }));

        const countdownInterval = setInterval(() => {
            setCountdowns(prevCountdowns => {
                const newCountdown = prevCountdowns[taskId] - 1;
                if (newCountdown <= 0) {
                    clearInterval(countdownInterval);
                    setCountdowns(prevState => ({ ...prevState, [taskId]: null }));
                    setButtonText(prevState => ({ ...prevState, [taskId]: 'Verifying' }));

                    const saveTaskToUser = async () => {
                        try {
                            const { data, error } = await supabase
                                .from('telegramUsers')
                                .update({ manualTasks: [...userManualTasks, { taskId: taskId, completed: false }] })
                                .eq('userId', userId);

                            if (error) {
                                throw error;
                            }

                            setUserManualTasks(prevTasks => [...prevTasks, { taskId: taskId, completed: false }]);
                            console.log(`Task ${taskId} added to user's manualTasks collection`);
                        } catch (error) {
                            console.error("Error adding task to user's document: ", error);
                        }
                    };

                    saveTaskToUser();

                    setSubmittedManual(prevState => ({ ...prevState, [taskId]: true }));
                    localStorage.setItem(`submittedManual_${taskId}`, true);

                    return { ...prevCountdowns, [taskId]: null };
                }
                const saveTaskToUser = async () => {
                    try {
                        const { data, error } = await supabase
                            .from('telegramUsers')
                            .update({ manualTasks: [...userManualTasks, { taskId: taskId, completed: false }] })
                            .eq('userId', userId);

                        if (error) {
                            throw error;
                        }

                        setUserManualTasks(prevTasks => [...prevTasks, { taskId: taskId, completed: false }]);
                        console.log(`Task ${taskId} added to user's manualTasks collection`);
                    } catch (error) {
                        console.error("Error adding task to user's document: ", error);
                    }
                };

                saveTaskToUser();
                return { ...prevCountdowns, [taskId]: newCountdown };
            });
        }, 1000);
    };

    const claimTask = async (taskId) => {
        setClaiming(prevState => ({ ...prevState, [taskId]: true }));
        try {
            const task = manualTasks.find(task => task.id === taskId);
            if (!task) {
                throw new Error('Task not found.');
            }
    
            const bonus = parseFloat(task.bonus);
            if (isNaN(bonus)) {
                throw new Error('Invalid bonus value. Bonus must be a valid number.');
            }
    
            const { data: userData, error: fetchError } = await supabase
                .from('telegramUsers')
                .select('*')
                .eq('userId', userId)
                .single();
    
            if (fetchError || !userData) {
                throw new Error('Error fetching user data: ' + fetchError.message);
            }
    
            const newBalance = parseFloat(userData.balance) + bonus;
            const newTaskPoints = parseFloat(userData.taskPoints || 0) + bonus;
    
            const { error: updateError } = await supabase
                .from('telegramUsers')
                .update({
                    manualTasks: userManualTasks.map(task =>
                        task.taskId === taskId ? { ...task, completed: true } : task
                    ),
                    balance: newBalance,
                    taskPoints: newTaskPoints,
                })
                .eq('userId', userId);
    
            if (updateError) {
                throw new Error('Error updating user data: ' + updateError.message);
            }
    
            setBalance(newBalance);
            setTaskPoints(newTaskPoints);
            setUserManualTasks(prevTasks =>
                prevTasks.map(task =>
                    task.taskId === taskId ? { ...task, completed: true } : task
                )
            );
    
            setModalMessage(
                <>
                    <div className="w-full flex justify-center flex-col items-center space-y-3">
                        <div className="w-full items-center justify-center flex flex-col space-y-2">
                            <IoCheckmarkCircleSharp size={32} className="text-btn4" />
                            <p className='font-medium text-center'>Let's go!!</p>
                        </div>
                        <h3 className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2">
                            <span className="text-btn4">+{formatNumber(bonus)}</span> $USDT CLAIMED
                        </h3>
                        <p className="pb-6 text-[15px] w-full text-center">
                            Keep performing new tasks! Something huge is coming! Perform more and earn more $USDT now!
                        </p>
                    </div>
                    <div className="w-full flex justify-center">
                        <button
                            onClick={closeModal}
                            className={`bg-btn4 text-[#000] w-full py-3 px-3 flex items-center justify-center text-center rounded-[12px] font-semibold text-[16px]`}
                        >
                            Continue tasks
                        </button>
                    </div>
                </>
            );
            setModalOpen(true);
            setClaimedBonus(bonus);
            setCongrats(true);
    
            setTimeout(() => {
                setCongrats(false);
            }, 4000);
        } catch (error) {
            console.error("Error updating task status to completed: ", error);
        }
        setClaiming(prevState => ({ ...prevState, [taskId]: false }));
    };
    
        

    const closeModal = () => {
        setModalOpen(false);
        setOpenTask(false);
    };

    const closeModal2 = () => {
        setModalOpen(false);
        setActive(false);
    };

    useEffect(() => {
        const submittedStatesManual = manualTasks.reduce((acc, task) => {
            const submittedStateManual = localStorage.getItem(`submittedManual_${task.id}`) === 'true';
            acc[task.id] = submittedStateManual;
            return acc;
        }, {});
        setSubmittedManual(submittedStatesManual);
    }, [manualTasks]);

    const formatNumber = (number) => {
        if (typeof number !== 'number' || isNaN(number)) {
          return '0.00000000'; // Default or fallback value
        }
      
        const formattedNumber = number.toFixed(8);
      
        const parts = formattedNumber.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      
        return parts.join(".");
    };
    const verifyTask = (taskId) => {
      // Implement the verification logic here
      console.log(`Verifying task with ID: ${taskId}`);
  };
      
    return (
        <>
 {manualTasks
    .filter(task => {
        if (!task.allowCountries) {
            return true;
        }
        const allowedCountries = task.allowCountries.split(',').map(country => country.trim());
        return allowedCountries.includes(userCountry);
    })
    .filter(task => {
        const userTask = userManualTasks.find(t => t.taskId === task.id);
        return !userTask || !userTask.completed;
    })
    .sort((a, b) => a.id - b.id)
    .map(task => {
        const userTask = userManualTasks.find(t => t.taskId === task.id);
        const isTaskSaved = !!userTask;
        const isTaskCompleted = userTask ? userTask.completed : false;
        return (
            <div key={task.id} onClick={() => setOpenTask(task)} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
                <div className='w-fit pr-2'>
                    <div className='flex items-center justify-center bg-[#1f2023] h-[45px] w-[45px] rounded-full p-1'>
                        <img alt="engy" src={task.icon} className='w-[20px]' />
                    </div>
                </div>
                <div className="flex h-full flex-1 flex-col justify-center relative">
                    <div className='flex w-full flex-col justify-between h-full space-y-1'>
                        <h1 className="text-[15px] text-nowrap line-clamp-1 font-medium">
                            {task.title}
                        </h1>
                        <span className='flex text-secondary items-center w-fit text-[15px]'>
                            <span className=''>
                                +{formatNumber(task.bonus)} $USDT
                            </span>
                        </span>
                    </div>
                </div>
                <div className='w-fit flex items-center justify-end flex-wrap text-[14px] relative'>
    {isTaskCompleted ? (
        <IoCheckmarkCircleSharp size={28} className="text-accent" />
    ) : showVerifyButtons[task.id] ? (
        <button
            onClick={() => claimTask(task.id)}
            className="bg-btn4 text-[#000] w-fit py-[10px] rounded-[30px] px-5 font-semibold ease-in duration-200"
        >
            Claim Reward
        </button>
    ) : (
        <button
            
            className="w-[78px] py-[10px] text-center absolute rounded-[30px] px-3 bg-[#1f2023] hover:bg-[#36373c] text-[#fff] font-semibold ease-in duration-200 "
        >
            Start
        </button>
    )}
</div>

            </div>
        );
    })}


            {openTask && (
                <TaskModal
                    task={openTask}
                    onClose={() => setOpenTask(null)}
                    performTask={performTask}
                    startCountdown={startCountdown}
                    claimTask={claimTask}
                    active={active}
                    setActive={setActive}
                    showVerifyButtons={showVerifyButtons}
                    countdowns={countdowns}
                    buttonText={buttonText}
                    submittedManual={submittedManual}
                    claiming={claiming}
                    userManualTasks={userManualTasks}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    closeModal2={closeModal2}
                    congrats='/congrats.gif'
                    claimedBonus={claimedBonus}
                />
            )}
            <div className='w-full absolute top-[50px] left-0 right-0 flex justify-center z-[60] pointer-events-none select-none'>
                {congrats ? (<img src='/congrats.gif' alt="congrats" className="w-[80%]" />) : (<></>)}
            </div>
            <div
                className={`${modalOpen ? "visible" : "invisible"} fixed top-[-12px] bottom-0 left-0 z-50 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
            >
                <div className={`${modalOpen ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"} w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}>
                    {modalMessage}
                </div>
            </div>
        </>
    );
};

const TaskModal = ({
    task,
    onClose,
    performTask,
    startCountdown,
    claimTask,
    showVerifyButtons,
    countdowns,
    buttonText,
    submittedManual,
    claiming,
    userManualTasks,
    active,
    setActive,
    modalMessage,
    modalOpen,
    closeModal,
    setModalMessage,
    setModalOpen,
    closeModal2,
    congrats,
    claimedBonus
}) => {
    const isTaskSaved = !!userManualTasks.find(t => t.taskId === task.id);
    const isTaskCompleted = isTaskSaved && userManualTasks.find(t => t.taskId === task.id).completed;
    const formatNumber = (num) => {
        if (num < 100000) {
            return new Intl.NumberFormat().format(num).replace(/,/g, " ");
        } else if (num < 1000000) {
            return new Intl.NumberFormat().format(num).replace(/,/g, " ");
        } else {
            return (num / 1000000).toFixed(3).replace(".", ".") + " M";
        }
    };

    const notVerified = () => {
        setModalOpen(true)
        setModalMessage(
            <>
                <div className="w-full flex justify-center flex-col items-center space-y-3">
                    <div className="w-full items-center justify-center flex flex-col space-y-2">
                        <CiNoWaitingSign size={32} className="text-btn4" />
                        <p className='font-medium text-center'>You have not completed this task yet!</p>
                    </div>
                    <p className="pb-6 text-[#9a96a6] text-[15px] w-full text-center">
                        Click on the start task button and ensure you complete the task before you come back to claim your reward.
                    </p>
                </div>
                <div className="w-full flex justify-center">
                    <button
                        onClick={closeModal2}
                        className={`bg-btn4 text-[#000]  w-full py-3 px-3 flex items-center justify-center text-center rounded-[12px] font-semibold text-[16px]`}
                    >
                        Okay, Understood!
                    </button>
                </div>
            </>
        );
    }

    return (
        <div className="fixed z-50 left-0 right-0 top-[-12px] bottom-0 flex justify-center taskbg px-[16px]">
            <div className="w-full flex flex-col items-center justify-start pt-5">
                <div className="flex w-full flex-col">
                    <div className='w-full flex justify-end pt-4 pb-8'>
                        <button
                            onClick={onClose}
                            className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-6 text-center font-medium text-[16px]"
                        >
                            <IoClose size={20} className="text-[#9995a4]" />
                        </button>
                    </div>
                    <h1 className="text-[20px] font-semibold w-full text-center">{task.title}</h1>
                    <p className="text-secondary text-[15px] font-medium pt-1 pb-4 w-full text-center">
                        {task.description}
                    </p>
                    <div className="bg-cards rounded-[10px] p-[14px] flex items-center justify-between mb-3">
                        <div className='flex flex-1 items-center space-x-1'>
                            <div className="">
                                <img src="/coin.webp" className="w-[16px]" alt="Coin Icon" />
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="font-semibold text-secondary">Reward:</span>
                                <span className="font-semibold">+{formatNumber(task.bonus)}</span>
                            </div>
                        </div>
                        {isTaskSaved || isTaskCompleted ? (
                            <span className="text-center text-[14px] font-semibold text-[#49ee49] flex items-center space-x-1">
                                <span className=''>
                                    Done
                                </span>
                                <IoCheckmarkCircle size={20} className='' />
                            </span>
                        ) : (
                            <span className="w-[14px] h-[14px] animate-pulse bg-btn4 rounded-full flex items-center" />
                        )}
                    </div>
                    <div className="bg-cards rounded-[10px] p-[14px] flex flex-col space-y-2 justify-center items-center">
                        <button
                            onClick={() => performTask(task.id)}
                            className="w-full py-[10px] px-4 font-medium bg-btn hover:bg-btn3 hover:text-[#000] ease-in duration-200 rounded-[6px]"
                        >
                            Start Task
                        </button>
                        {isTaskSaved || isTaskCompleted ? (
                            <span className="w-full py-[10px] px-4 font-medium bg-cards text-center text-[#7cf47c] ease-in duration-200 rounded-[6px]">
                                Done
                            </span>
                        ) : (
                            <>
                            </>
                        )}
                        <div className='w-full flex flex-col space-y-2 items-center justify-center'>
                            <div className='w-full flex items-center text-[14px] justify-center relative mt-1 mb-4'>
                                {!showVerifyButtons[task.id] ? (
                                    <button
                                        onClick={notVerified}
                                        disabled={!active}
                                        className={`${active ? 'bg-btn4 text-[#000]' : 'bg-[#383838] text-[#888]'} w-full py-3 px-4 font-semibold rounded-[6px] text-[17px]`}
                                    >
                                        Verify
                                    </button>
                                ) : (
                                    <>
                                        {!isTaskSaved && !isTaskCompleted && (
                                            <>
                                                {countdowns[task.id] === undefined && (
                                                    <button
                                                        onClick={() => startCountdown(task.id)}
                                                        className={`${submittedManual[task.id] ? "bg-btn4 text-[#000]" : buttonText[task.id] || "bg-btn4 text-[#000]"} ${!showVerifyButtons[task.id] ? "!bg-[#383838] !text-[#888]" : "bg-btn3 text-[#000]"} w-full py-3 px-4 font-semibold rounded-[6px] text-[17px]`}
                                                        disabled={!showVerifyButtons[task.id]}
                                                    >
                                                        {submittedManual[task.id] ? 'Verify' : 'Verify'}
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {countdowns[task.id] !== null && countdowns[task.id] !== undefined && (
                                    <span className="w-full py-3 px-4 font-medium text-center rounded-[6px] bg-[#383838] text-[#fff]">
                                        checking {countdowns[task.id]}s
                                    </span>
                                )}
                            </div>
                            <button
                                onClick={() => claimTask(task.id)}
                                className={`${isTaskSaved && !isTaskCompleted ? 'bg-btn4 text-[#000]' : 'bg-[#383838] text-[#888]'} w-full py-4 px-3 mt-6 flex items-center rounded-[12px] justify-center text-center text-[18px] font-semibold`}
                                disabled={claiming[task.id] || !isTaskSaved || isTaskCompleted}
                            >
                              {!isTaskCompleted ? 'Finish Task' : 'Task Completed'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManualTasks;
