import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

const friendsRewards = [
  { title: 'Invite 10 friends', referralsRequired: 10, bonusAward: 0.1 },
  { title: 'Invite 25 friends', referralsRequired: 25, bonusAward: 0.3 },
  { title: 'Invite 50 friends', referralsRequired: 50, bonusAward: 1.0 },
  { title: 'Invite 100 friends', referralsRequired: 100, bonusAward: 3.0 },
  { title: 'Invite 500 friends', referralsRequired: 500, bonusAward: 20.0 },
  { title: 'Invite 1000 friends', referralsRequired: 1000, bonusAward: 50.0 },
  { title: 'Invite 5000 friends', referralsRequired: 5000, bonusAward: 300.0 },
];

const RefRewards = () => {
  const { referrals, balance, setBalance, id: userId, setTaskPoints } = useUser();
  const [claimedReferralRewards, setClaimedReferralRewards] = useState([]);
  const [processingReward, setProcessingReward] = useState(null); // Track which reward is being claimed
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [claimingReward, setClaimingReward] = useState(null); // Track the reward being claimed
  const [congrats, setCongrats] = useState(false);

  useEffect(() => {
    const syncClaimedRewards = async () => {
      try {
        // Fetch claimed rewards from the database
        const { data, error } = await supabase
          .from('telegramUsers')
          .select('claimed_referral_rewards')
          .eq('userId', userId)
          .single();

        if (error) throw error;

        const rewardsFromDB = data?.claimed_referral_rewards || [];
        setClaimedReferralRewards(rewardsFromDB);

        // Save to localStorage
        localStorage.setItem('claimedReferralRewards', JSON.stringify(rewardsFromDB));
      } catch (error) {
        console.error('Error syncing claimed rewards:', error);
      }
    };

    if (userId) {
      syncClaimedRewards();
    }
  }, [userId]);

  const handleClaim = async (reward) => {
    if (processingReward || claimedReferralRewards.includes(reward.title)) return; // Prevent multiple clicks
  
    if (referrals.length >= reward.referralsRequired) {
      setProcessingReward(reward.title); // Mark reward as processing
  
      const newBalance = balance + reward.bonusAward;
      try {
        // Fetch the current claimed_referral_rewards from the database
        const { data: currentData, error: fetchError } = await supabase
        .from('telegramUsers')
        .select('claimed_referral_rewards')
        .eq('userId', userId)
        .single();
      
  
        if (fetchError) throw fetchError;
  
        const currentClaimedRewards = currentData?.claimed_referral_rewards || [];
        
        // Append the new reward to the existing claimed rewards list
        const updatedClaimedRewards = [...currentClaimedRewards, reward.title];

  
        // Update the database with the new claimed rewards list
        const { data, error } = await supabase
        .from('telegramUsers')
        .update({
          balance: newBalance,
          task_points: supabase.rpc('increment', { x: reward.bonusAward }),
          claimed_referral_rewards: updatedClaimedRewards,
        })
        .eq('userId', userId)
        .select('claimed_referral_rewards')
        .single();
  
        if (error) throw error;
  
        // Update local state
        setBalance(newBalance);
        setTaskPoints((prevTaskPoints) => prevTaskPoints + reward.bonusAward);
        setClaimedReferralRewards(updatedClaimedRewards);
  
        // Update localStorage
        localStorage.setItem('claimedReferralRewards', JSON.stringify(updatedClaimedRewards));
  
        // Show success modal
        setModalMessage(
          <div className="w-full flex justify-center flex-col items-center space-y-3">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <IoCheckmarkCircleSharp size={32} className="text-accent" />
              <p className="font-medium text-center">Great job!</p>
            </div>
            <h3 className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2">
              <span className="text-accent">+{reward.bonusAward.toFixed(2)}</span> $USDT CLAIMED
            </h3>
            <p className="pb-6 text-[#bfbfbf] text-[15px] w-full text-center">
              Keep inviting friends to unlock new rewards! Amazing things await!
            </p>
          </div>
        );
  
        setModalOpen(true);
      } catch (error) {
        console.error('Error claiming referral reward:', error);
      } finally {
        setProcessingReward(null); // Reset processing state
      }
    } else {
      setModalMessage('You do not meet the requirements for this reward.');
      setModalOpen(true);
    }
  };
  
  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }

    const formattedNumber = number.toFixed(8);
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {friendsRewards.map((reward) => {
        const isClaimable = referrals.length >= reward.referralsRequired;
        const isClaimed = claimedReferralRewards.includes(reward.title);
        const isClaiming = claimingReward === reward.title;

        return (
          <div key={reward.title} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
            <div className="w-fit pr-2">
              <div className="flex items-center justify-center bg-[#1f2023] h-[45px] w-[45px] rounded-full p-1">
                <img alt="invite" src="/invite.svg" className="w-[20px]" />
              </div>
            </div>
            <div className="flex h-full flex-1 flex-col justify-center relative">
              <div className="flex w-full flex-col justify-between h-full space-y-1">
                <h1 className="text-[15px] line-clamp-1 font-medium">{reward.title}</h1>
                <span className="flex text-secondary items-center w-fit text-[15px]">
                  <span>+{formatNumber(reward.bonusAward)} $USDT</span>
                </span>
              </div>
            </div>
            <div className="w-fit flex items-center justify-end flex-wrap text-[14px] relative">
              <button
                disabled={!isClaimable || isClaimed || isClaiming}
                onClick={() => handleClaim(reward)}
                className={`${
                  isClaimable && !isClaimed && !isClaiming ? 'bg-btn text-[#000]' : 'bg-[#1f2023] text-[#888]'
                } w-fit py-[10px] rounded-[30px] px-5 font-semibold ease-in duration-200`}
              >
                {isClaimed ? 'Claimed' : isClaiming ? 'Processing...' : 'Claim'}
              </button>
            </div>
          </div>
        );
      })}

      {congrats && (
        <div className="w-full absolute top-[50px] left-0 right-0 flex justify-center z-50 pointer-events-none select-none">
          <img src="/congrats.gif" alt="congrats" className="w-[80%]" />
        </div>
      )}

      {modalOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4">
          <div className="w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8 ease-in duration-300 transition-all">
            {modalMessage}
            <div className="w-full flex justify-center">
              <button
                onClick={closeModal}
                className="bg-btn4 w-fit py-[10px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
              >
                Continue to next
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RefRewards;